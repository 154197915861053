<template>
  <div class="main">
    <div class="categories content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="/">
        <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Category
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="category-count">
            {{courseCategories.length}} Categories
          </div>
        </a-col>
        <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="add">
            <a-button type="primary">
              <router-link to="/category/add"><PlusOutlined /> Add Category</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <template class="category" v-if="courseCategories.length == 0">
        <a-empty />
      </template>
      <a-row class="body">
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="category-row" v-for="courseCategory in courseCategories" :key="courseCategory._id">
          <div class="category">
            <div class="header-image" v-bind:style="{ backgroundImage: 'url(' + courseCategory.image + ')' }">
            </div>
            <div class="title">
              <h4>{{courseCategory.title}}</h4>
              <div class="sub-cat">{{courseCategory.child.length}} Sub Categories
                <a-button size="small" type="primary" ghost class="edit" @click="addSubCat(courseCategory)">
                  <PlusOutlined /> Add Sub
                </a-button>
              </div>
              <hr />
            </div>
            <div class="body">
              <div class="sub-category" v-for="subCategory in courseCategory.child" :key="subCategory._id">
                <div class="name">{{subCategory.title}}</div>
                <div class="action">
                  <a-tooltip>
                  <template #title>Edit</template>
                  <EditOutlined @click="showSubEditForm(subCategory)" />
                  </a-tooltip>
                  <a-tooltip>
                  <template #title>Delete</template>
                  <DeleteOutlined style="margin-left:5px;" @click="showDeleteConfirm(courseCategory,subCategory,'sub')" />
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="action">
                <a-button type="primary" ghost class="edit" @click="showEditForm(courseCategory)">
                  <EditOutlined /> Edit
                </a-button>
                <a-button
                  type="primary"
                  ghost
                  class="delete"
                  @click="showDeleteConfirm(courseCategory,'','parent')"
                >
                  Delete <DeleteOutlined />
                </a-button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { createVNode, defineComponent, ref, onMounted } from 'vue'
import { Modal, notification } from 'ant-design-vue'
import { ExclamationCircleOutlined, HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import category from '../../../services/category'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
  },
  setup () {
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const courseCategories = ref<Array<{ _id: string; title: string; description: string; image: string; parent_id: string }>>([])
    const showDeleteConfirm = (courseCategory, subcategory, type) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk () {
          try {
            if (type === 'parent') {
              category.deleteCategory(courseCategory._id)
              const index = courseCategories.value.findIndex((n) => n._id === courseCategory._id)
              if (index >= 0) {
                courseCategories.value.splice(index, 1)
              }
            } else if (type === 'sub') {
              category.deleteCategory(subcategory._id)
              const index = courseCategory.child.findIndex((n) => n._id === subcategory._id)
              if (index >= 0) {
                courseCategory.child.splice(index, 1)
              }
            }
          } catch (error) {
            console.log(error)
            notify('Error', error.data, 'error')
          }
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const getCategories = async () => {
      try {
        const res = await category.getCategory()
        courseCategories.value = res.data
      } catch (error) {
        console.log(error)
        notify('Error', error.data, 'error')
      }
    }
    const router = useRouter()
    const showEditForm = (courseCategory) => {
      router.push({
        path: '/category/edit/' + courseCategory._id
      })
    }
    const showSubEditForm = (courseCategory) => {
      router.push({
        path: '/category/edit/' + courseCategory._id + '/sub'
      })
    }
    const addSubCat = (courseCategory) => {
      router.push({
        path: '/category/add/' + courseCategory._id + '/sub-category'
      })
    }
    onMounted(() => {
      getCategories()
    })
    return {
      showDeleteConfirm,
      notify,
      getCategories,
      courseCategories,
      showEditForm,
      addSubCat,
      showSubEditForm
    }
  }
})
</script>

<style lang="scss">
.categories {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
    .category-count {
      float: left;
    }
  }
  .body {
    .category-row {
      padding: 10px;
      .category {
        border-radius: 15px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 10px #00000014;
        height: 100%;
        .header-image {
          height: 200px;
          background-size: cover;
          background-repeat: no-repeat;
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
        }
        .title {
          padding: 10px 20px 0;
          text-align: left;
          h4 {
            color: #707070;
            font-family: "TT Norms Pro Medium";
            font-size: 16px;
            text-transform: uppercase;
          }
          .sub-cat {
            font-family: "TT Norms Pro Light";
            font-size: 12px;
            margin-bottom: 15px;
            button {
              float: right;
              color: #2ecc71;
              border-color: #2ecc71;
              width: 100px;
              border-radius: 20px;
              margin-right: 10px;
              font-family: "TT Norms Pro Medium";
            }
          }
          hr {
            height: 1px;
            background-color: #e8e9ec;
            border: none;
          }
        }
        .body {
          text-align: left;
          padding: 0 20px;
          color: #707070;
          font-size: 13px;
          padding-bottom: 82px;
          .sub-category {
            border-bottom: 1px solid #e8e9ec;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            .name {
              width: 80%;
            }
            .action {
              width: 20%;
            }
          }
        }
        .footer {
          padding: 20px 0;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          .action {
            button {
              width: 100px;
              border-radius: 20px;
              margin-right: 10px;
              font-family: "TT Norms Pro Medium";
              margin-bottom: 10px;
            }
            .edit {
              color: #2ecc71;
              border-color: #2ecc71;
            }
            .delete {
              color: #ff5656;
              border-color: #ff5656;
            }
          }
        }
      }
    }
  }
}
</style>
